import React from 'react'

import AddAndRedirect from 'src/components/AddAndRedirect'

const AddFrameThenCart = () => (
  <>
    <AddAndRedirect products={['frame']} redirectTo="/cart/" />
  </>
)

export default AddFrameThenCart
